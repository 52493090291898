<template>
  <div>
    <v-container class="pt-0 pb-12">
      <v-row>
        <v-col cols="12" sm="10" offset-sm="1">
          <v-card flat class="grey lighten-5 mt-5 mb-12">
            <v-card-title class="secondary lighten-1 white--text">
              <v-row justify="center">
                <v-col
                  cols="12"
                  md="10"
                  class="my-0 d-flex justify-space-between"
                >
                  Add a new buyer
                  <v-icon color="white">mdi-account-plus</v-icon>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pt-3 pb-0">
              <v-row justify="center">
                <v-col cols="12" md="10" class="my-0 d-flex justify-end">
                  <v-btn @click="clearDialog" color="red" outlined small
                    >clear form</v-btn
                  >
                </v-col>
              </v-row>
              <v-form ref="form" @submit.stop v-model="valid">
                <v-row justify-md="center">
                  <v-col cols="4" sm="2">
                    <v-text-field
                      v-model="title"
                      color="green"
                      filled
                      :dense="$vuetify.breakpoint.xsOnly"
                      label="Title"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-none d-sm-flex" cols="8"></v-col>
                  <v-col cols="12" sm="6" md="5" class="mt-n5">
                    <v-text-field
                      required
                      v-model="forename"
                      :rules="nameRules"
                      color="green"
                      filled
                      :dense="$vuetify.breakpoint.xsOnly"
                      label="Forename"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="5" class="mt-n5">
                    <v-text-field
                      required
                      v-model="surname"
                      :rules="nameRules"
                      color="green"
                      filled
                      :dense="$vuetify.breakpoint.xsOnly"
                      label="Surname"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="5" class="mt-1">
                    <v-text-field
                      required
                      v-model="email"
                      :rules="emailRules"
                      color="green"
                      filled
                      :dense="$vuetify.breakpoint.xsOnly"
                      label="Email"
                      append-icon="mdi-at"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="5" class="d-none d-sm-flex"></v-col>
                  <v-col cols="12" sm="6" md="5" class="mt-n5">
                    <v-text-field
                      v-model="mobile"
                      required
                      :rules="mobileRules"
                      color="green"
                      filled
                      :dense="$vuetify.breakpoint.xsOnly"
                      label="Mobile number"
                      append-icon="mdi-cellphone-basic"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="5" class="mt-n5">
                    <v-text-field
                      v-model="landline"
                      color="green"
                      :dense="$vuetify.breakpoint.xsOnly"
                      label="Landline"
                      append-icon="mdi-phone"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col class="d-none d-sm-flex" cols="4"></v-col> -->
                  <v-col cols="12" sm="6" md="5" lg="4" class="mt-n5">
                    <v-select
                      filled
                      color="green"
                      :dense="$vuetify.breakpoint.xsOnly"
                      :items="preferredContactMethod"
                      v-model="selectedContactMethod"
                      label="Preferred contact method"
                    ></v-select>
                  </v-col>
                  <v-col class="d-none d-md-flex" cols="5" lg="6"></v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="py-5 px-4">
              <v-row justify-md="center">
                <v-col class="py-0" cols="10">
                  <v-btn
                    @click="submitForm"
                    :disabled="!valid"
                    color="blue"
                    width="150"
                    height="45"
                    :loading="loading"
                  >
                    <span class="white--text">Submit</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <!-- API response dialog -->
      <v-dialog
        v-model="confirmationDialog"
        scrollable
        persistent
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-text class="pa-5">
            <div v-if="buyerCreated">
              <p class="green--text font-weight-bold text-h5">
                Buyer successfully created
              </p>
              <p class="text-subtitle-1">
                {{ title }} {{ forename }} {{ surname }}
              </p>
              <p class="my-0">{{ email }}</p>
              <p class="my-0">{{ mobile }} - {{ landline }}</p>
              <p class="my-0">Contact method: {{ selectedContactMethod }}</p>
            </div>
            <div v-else>
              <p class="red--text font-weight-bold text-h5">
                Error whilst creating new buyer.
              </p>
              <p class="red--text">Please try again.</p>
              <p class="red--text">
                If the problem persists, please contact support.
              </p>
            </div>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <v-btn @click="closeDialog" color="blue" class="white--text" large
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "create-buyer",
  data() {
    return {
      //dialog
      confirmationDialog: false,
      buyerCreated: false,
      //form
      loading: false,
      valid: false,
      preferredContactMethod: ["Telephone", "SMS", "Email", "Letter"],
      nameRules: [
        (v) => !!v || "Field is required",
        // v => v.length >= 2 || 'Name must be at least 2 characters',
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      mobileRules: [
        (v) => !!v || "Mobile number is required",
        // v => /.+@.+/.test(v) || 'Enter a valid UK number',
      ],
      //submission model values
      title: "",
      forename: "",
      surname: "",
      email: "",
      mobile: "",
      landline: "",
      selectedContactMethod: "",
    };
  },
  created(){
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user === ''){
      this.$router.push('/');
    }
  },
  methods: {
    closeDialog() {
      this.confirmationDialog = false;
      this.closeDialog();
    },
    clearDialog() {
      this.$refs.form.reset();
      this.title = "";
      this.forename = "";
      this.surname = "";
      this.email = "";
      this.mobile = "";
      this.landline = "";
      this.selectedContactMethod = "";
    },
    submitForm() {
      this.loading = true;
      const model = {
        Titel: this.title,
        Forename: this.forename,
        Surname: this.surname,
        Email: this.email,
        Mobile: this.mobile,
        Landline: this.landline,
        PreferredContactMethod: this.selectedContactMethod,
      };
      this.$store
        .dispatch("OffersApplications/createApplicantBuyer", model)
        .then(() => {
          this.buyerCreated = true;
          this.confirmationDialog = true;
          this.loading = false;
        })
        .catch(() => {
          this.confirmationDialog = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>